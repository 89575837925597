import {
  Component,
  ContextType,
  FC,
  Fragment,
  PropsWithChildren,
  ReactEventHandler,
  ReactNode,
  SyntheticEvent,
} from "react";
import { IconArrowsHorizontal, IconChevronDownSmall, IconProfileOutlined } from "@hubble/icons";
import _ from "lodash";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { AnalyticsEventName, AnalyticsProperty } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { FeatureFlagContext } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import GroupDropdown from "@gemini-ui/components/Header/GroupDropdown";
import CardLink from "@gemini-ui/components/Header/navigation/CardLink";
import {
  getMyAccountMenuData,
  getOtherTransferMenuData,
  getTransferMenuData,
  HEADER_MENU,
} from "@gemini-ui/components/Header/navigation/data/geminiMenuData";
import Copyright from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenu/Copyright";
import MenuContainer from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenu/MenuContainer";
import {
  ItemLink,
  ListContainer,
  StyledListItem,
  SubItemList,
  Subtext,
  UserContainer,
} from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenu/styles";
import { TradingInterfaceSwitcher } from "@gemini-ui/components/Header/navigation/TradingInterfaceSwitcher";
import { handleLogout } from "@gemini-ui/components/Header/navigation/utils";
import { handleChangeFiatSubmit } from "@gemini-ui/components/Header/utils";
import { VerificationPendingActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { GroupsInfo } from "@gemini-ui/constants/templateProps/users";
import { GlobalModalType, useGlobalModal, VerificationPendingProps } from "@gemini-ui/contexts/GlobalModal";
import { usePageData } from "@gemini-ui/contexts/PageData";
import { Colors, Flex, Spacer } from "@gemini-ui/design-system";
import FiatIcons from "@gemini-ui/images/icons/nav/fiat";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { useEarnDistributionsFlags } from "@gemini-ui/pages/Earn/hooks/useEarnDistributionsFlags";
import { useIsQualifiedForCustodyBilling } from "@gemini-ui/pages/settings/Billing/utils";
import { getIsUserInOnboarding, useLockout } from "@gemini-ui/services/user/lockout";
import { handleCashDepositClick } from "@gemini-ui/utils/handleCashDepositClick";
import { handleCashWithdrawClick } from "@gemini-ui/utils/handleCashWithdrawClick";
import {
  handleCryptoDepositInitiation,
  handleCryptoWithdrawInitiation,
} from "@gemini-ui/utils/handleCryptoDepositInitiation";
import { defineMessage, IntlShape, useIntl, withIntl } from "@gemini-ui/utils/intl";

const signOutUrl = jsRoutes.controllers.account.Application.signOut().url;

interface Props {
  userName: string;
  institutionName: string;
  advancedTradeUIEnabled: boolean;
  showClearingLink: boolean;
  defaultFiat: CurrencyShortNameFiat;
  supportedFiat: CurrencyShortNameFiat[];
  isCustodyAccount: boolean;
  pageName: PageName;
  moreThanOneAccount: boolean;
  groupsInfo: GroupsInfo;
  countryCode: CountryAbbreviation;
  isInstitutional: boolean;
  intl: IntlShape;
  toggleDepositCashModal: () => void;
  togglePerpsOnboardingTransferModal?: () => void;
  toggleMobileMenu: () => void;
  toggleCashWithdrawModal: () => void;
  toggleDerivativeDebitCardFundingModal?: () => void;
  toggleCryptoDepositModal: () => void;
  toggleCryptoWithdrawModal: () => void;
}

interface State {
  showFiatMenu: boolean;
  showAccountMenu: boolean;
  showTransferMenu: boolean;
}

interface MobileNavLinkProps {
  href: string;
  target?: string;
  onClick?: ReactEventHandler<HTMLAnchorElement>;
  icon?: ReactNode;
}

const MobileNavLink: FC<PropsWithChildren<MobileNavLinkProps>> = ({ children, href, target, onClick, icon }) => (
  <ItemLink
    rel="noopener noreferrer"
    href={href}
    target={target}
    active={window.location.pathname === href}
    onClick={onClick}
  >
    <Flex>
      {Boolean(icon) && (
        <Spacer as="span" mr={1}>
          {icon}
        </Spacer>
      )}
      <span>{children}</span>
    </Flex>
  </ItemLink>
);

const StakeLink = ({ pageName, toggleMobileMenu }: { pageName: PageName; toggleMobileMenu: () => void }) => {
  const { intl } = useIntl();
  const { eligibleForGrow } = useGrowFeatureFlags();
  const { lockout } = useLockout();
  const { toggleModal } = useGlobalModal();
  if (!eligibleForGrow) return null;

  const isOnboardingInProgress = getIsUserInOnboarding(lockout);

  return (
    <StyledListItem>
      <ItemLink
        active={pageName === PageName.Stake}
        href={isOnboardingInProgress ? "#" : "/stake"}
        onClick={e => {
          if (isOnboardingInProgress) {
            e.preventDefault();
            toggleMobileMenu();
            toggleModal<VerificationPendingProps>(GlobalModalType.VerificationPending, {
              actionType: VerificationPendingActionType.Stake,
            });
          }
        }}
        big
      >
        {intl.formatMessage({ defaultMessage: "Stake" })}
      </ItemLink>
    </StyledListItem>
  );
};

const MarketsLink = ({ pageName, "data-testid": dataTestId }: { pageName: PageName; "data-testid": string }) => {
  const { intl } = useIntl();

  return (
    <FeatureFlagContext.Consumer>
      {flags =>
        flags?.AdvancedMarketsPageEnabled && (
          <StyledListItem>
            <ItemLink
              active={pageName === PageName.AdvancedMarkets}
              href="/advanced/markets"
              big
              data-testid={dataTestId}
            >
              {intl.formatMessage({
                defaultMessage: "Markets",
              })}
            </ItemLink>
          </StyledListItem>
        )
      }
    </FeatureFlagContext.Consumer>
  );
};

const AccountMenuData = () => {
  const { intl } = useIntl();
  const {
    templateProps: {
      user: { isCustodyAccount, isInstitutional },
    },
  } = usePageData();

  const { isEarnDistributionsPageEnabled } = useEarnDistributionsFlags();
  const { isQualified, hasFetched } = useIsQualifiedForCustodyBilling();
  const shouldShowBillingTab = isQualified && hasFetched;

  return (
    <Fragment>
      {getMyAccountMenuData(
        intl,
        shouldShowBillingTab,
        isCustodyAccount,
        isEarnDistributionsPageEnabled,
        isInstitutional
      ).map(item => (
        <MobileNavLink key={item.name} href={item.url} target={item.target} onClick={item.onClick}>
          {item.name}
        </MobileNavLink>
      ))}
    </Fragment>
  );
};

class OffcanvasMenu extends Component<Props, State> {
  static contextType = AlertContext;
  declare context: ContextType<typeof AlertContext>;

  state = {
    showTransferMenu: this.props.pageName === PageName.Transfer,
    showFiatMenu: false,
    showAccountMenu: _.includes(this.props.pageName, "Settings"),
  };

  toggleMenu = (name: keyof State) => {
    this.setState(state => ({ ...state, [name]: !state[name] }));
  };

  static defaultProps = { extraFeatures: [] };

  handleClick = (
    e: SyntheticEvent<HTMLAnchorElement>,
    id: string,
    href: string,
    analyticsEventName: AnalyticsEventName,
    analyticsProperties: AnalyticsProperty
  ) => {
    const {
      toggleMobileMenu,
      toggleDepositCashModal,
      toggleCashWithdrawModal,
      toggleCryptoDepositModal,
      toggleCryptoWithdrawModal,
    } = this.props;
    e.preventDefault();
    if (id === "deposit-cash") {
      handleCashDepositClick(e, toggleDepositCashModal, HEADER_MENU);
    } else if (id === "withdraw-cash") {
      handleCashWithdrawClick(e, toggleCashWithdrawModal, HEADER_MENU, href);
    } else if (id === "deposit-crypto") {
      handleCryptoDepositInitiation(e, toggleCryptoDepositModal, analyticsEventName, analyticsProperties);
    } else if (id === "withdraw-crypto") {
      handleCryptoWithdrawInitiation(e, toggleCryptoWithdrawModal, analyticsEventName, analyticsProperties);
    } else {
      mixpanelClient.trackLinkClickAndRedirectManually(href, analyticsEventName, analyticsProperties);
    }
    toggleMobileMenu();
  };

  menuLinks() {
    const {
      advancedTradeUIEnabled,
      showClearingLink,
      defaultFiat,
      supportedFiat,
      isCustodyAccount,
      pageName,
      moreThanOneAccount,
      userName,
      institutionName,
      groupsInfo,
      countryCode,
      isInstitutional,
      intl,
      togglePerpsOnboardingTransferModal,
      toggleMobileMenu,
      toggleDerivativeDebitCardFundingModal,
    } = this.props;
    const { showFiatMenu, showAccountMenu, showTransferMenu } = this.state;
    const DefaultFiatIcon = FiatIcons[defaultFiat] || FiatIcons["default"];
    const multiFiat = supportedFiat.length > 1;
    const showCardLink = countryCode === "us" && !isInstitutional;

    const isConsolidatedPortfolioEnabled = optimizelyClient.isFeatureEnabled(
      OPTIMIZELY_FEATURE_FLAGS.WEB_PORTFOLIO_CONSOLIDATION
    );
    const isDerivativeDebitCardFundingEnabled = optimizelyClient.isFeatureEnabled(
      OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING
    );

    return (
      <ListContainer data-testid="OffCanvasMenu">
        {isCustodyAccount ? (
          <StyledListItem>
            <ItemLink active={pageName === PageName.CustodyDashboard} href="/custody" big>
              {intl.formatMessage({ defaultMessage: "Dashboard" })}
            </ItemLink>
          </StyledListItem>
        ) : (
          <StyledListItem>
            <ItemLink active={pageName === PageName.Home || window?.location?.pathname === "/"} href="/" big>
              {intl.formatMessage({
                defaultMessage: "Home",
              })}
            </ItemLink>
          </StyledListItem>
        )}
        {!isCustodyAccount &&
          (advancedTradeUIEnabled ? (
            <Fragment>
              <StyledListItem>
                <ItemLink active={pageName === PageName.Trade} href="/" big>
                  {intl.formatMessage({ defaultMessage: "Trade" })}
                </ItemLink>
              </StyledListItem>
              <MarketsLink data-testid="markets-mobile-link" pageName={pageName} />
              <StakeLink pageName={pageName} toggleMobileMenu={toggleMobileMenu} />
              {showCardLink && <CardLink pageName={pageName} isMobile />}
            </Fragment>
          ) : (
            <Fragment>
              <StyledListItem>
                <ItemLink
                  active={pageName === PageName.Market || window?.location?.pathname === "/"}
                  href="/market"
                  big
                >
                  {intl.formatMessage({ defaultMessage: "Market" })}
                </ItemLink>
              </StyledListItem>
              <StakeLink pageName={pageName} toggleMobileMenu={toggleMobileMenu} />
              {showCardLink && <CardLink pageName={pageName} isMobile />}
            </Fragment>
          ))}

        {(isConsolidatedPortfolioEnabled || (!advancedTradeUIEnabled && !isCustodyAccount)) && (
          <StyledListItem>
            <ItemLink active={pageName === PageName.Portfolio} href="/portfolio" big>
              {intl.formatMessage({ defaultMessage: "Portfolio" })}
            </ItemLink>
          </StyledListItem>
        )}

        {showClearingLink && (
          <StyledListItem>
            <ItemLink
              data-testid="header-clearing"
              active={pageName === PageName.ClearingDashboard}
              href={jsRoutes.com.gemini.web.server.trading.controllers.GCOrderController.get().url}
              big
            >
              {intl.formatMessage({ defaultMessage: "Clearing" })}
            </ItemLink>
          </StyledListItem>
        )}

        {!isCustodyAccount && advancedTradeUIEnabled && !isConsolidatedPortfolioEnabled && (
          <StyledListItem>
            <ItemLink
              data-testid="header-balances"
              active={pageName === PageName.Balances || pageName === PageName.BalancesV2}
              href={jsRoutes.controllers.retail.BalanceControllerV2.get().url}
              big
            >
              {intl.formatMessage({ defaultMessage: "Balances" })}
            </ItemLink>
          </StyledListItem>
        )}

        <TradingInterfaceSwitcher />

        <StyledListItem onClick={() => this.toggleMenu("showTransferMenu")} border firstBorder>
          <ItemLink href="#" open={showTransferMenu}>
            <IconArrowsHorizontal color={Colors.white} size="xs" />
            <span>{intl.formatMessage({ defaultMessage: "Transfer" })}</span>
            <IconChevronDownSmall color={Colors.white} size="sm" />
          </ItemLink>
          <SubItemList className={showTransferMenu && "open"}>
            {getTransferMenuData(intl).map(({ name, url, id, icon: Icon, analyticsProperties, analyticsEventName }) => (
              <MobileNavLink
                key={id}
                icon={<Icon size="xs" color={Colors.white} />}
                onClick={e => this.handleClick(e, id, url, analyticsEventName, analyticsProperties)}
                href={url}
              >
                {name}
              </MobileNavLink>
            ))}
            {moreThanOneAccount &&
              getOtherTransferMenuData(moreThanOneAccount, intl).map(
                ({ id, url, name, analyticsEventName, analyticsProperties }) => (
                  <MobileNavLink
                    key={id}
                    href={url}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        id === "transfer-between" &&
                        pageName === PageName.Trade &&
                        togglePerpsOnboardingTransferModal
                      ) {
                        togglePerpsOnboardingTransferModal();
                      } else if (
                        id === "derivative-debit-card-funding" &&
                        isDerivativeDebitCardFundingEnabled &&
                        toggleDerivativeDebitCardFundingModal
                      ) {
                        toggleDerivativeDebitCardFundingModal();
                      } else {
                        this.handleClick(e, id, url, analyticsEventName, analyticsProperties);
                      }
                    }}
                  >
                    {name}
                  </MobileNavLink>
                )
              )}
          </SubItemList>
        </StyledListItem>
        {pageName !== PageName.Trade && multiFiat && (
          <StyledListItem onClick={() => this.toggleMenu("showFiatMenu")} border>
            <ItemLink href="#" open={showFiatMenu}>
              <DefaultFiatIcon />
              <span>{intl.formatMessage({ defaultMessage: "Default currency" })}</span>
              <IconChevronDownSmall color={Colors.white} size="sm" />
              <Subtext>
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "{supportedFiatLength} available {supportsMultipleCurrencies, select, true {currencies} other {currency}}",
                  }),
                  {
                    supportedFiatLength: supportedFiat.length,
                    supportsMultipleCurrencies: supportedFiat.length > 1,
                  }
                )}
              </Subtext>
            </ItemLink>
            <SubItemList className={showFiatMenu && "open"}>
              {supportedFiat.map(item => (
                <ItemLink
                  data-currency={item}
                  key={item}
                  active={showFiatMenu && defaultFiat === item}
                  onClick={handleChangeFiatSubmit(item, this.context.showAlert)}
                >
                  {item}
                </ItemLink>
              ))}
            </SubItemList>
          </StyledListItem>
        )}

        <StyledListItem onClick={() => this.toggleMenu("showAccountMenu")} border>
          <ItemLink href="#" open={showAccountMenu}>
            <IconProfileOutlined color={Colors.white} size="xs" />
            <span>{intl.formatMessage({ defaultMessage: "Account" })}</span>
            <IconChevronDownSmall color={Colors.white} size="sm" />
            <Subtext>{intl.formatMessage({ defaultMessage: "Profile, banks, settings" })}</Subtext>
          </ItemLink>
          <SubItemList className={showAccountMenu && "open"}>
            <AccountMenuData />
          </SubItemList>
        </StyledListItem>

        <StyledListItem>
          <UserContainer>
            <GroupDropdown userName={userName} institutionName={institutionName} groupsInfo={groupsInfo} />
            <ItemLink href={signOutUrl} onClick={handleLogout}>
              {intl.formatMessage({ defaultMessage: "Log out" })}
            </ItemLink>
          </UserContainer>
        </StyledListItem>
      </ListContainer>
    );
  }

  render() {
    return (
      <MenuContainer>
        {this.menuLinks()}
        <Copyright />
      </MenuContainer>
    );
  }
}

export default withIntl(OffcanvasMenu);
