import { EVENTS, track } from "@gemini-ui/analytics";
import { PhoneNumber } from "@gemini-ui/constants/phoneNumber";

export const formatPhoneNumber = (phoneNumber: PhoneNumber) => {
  return `+${phoneNumber.intlPrefix}${phoneNumber.number}`.split(" ").join("");
};

export const trackOnfidoEvent = event => {
  track(EVENTS.ON_FIDO.name, {
    [EVENTS.ON_FIDO.properties.EVENT_NAME]: event?.detail?.eventName,
  });
};
