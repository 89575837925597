import { ReactNode } from "react";
import { IconInfoFilled } from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import {
  CURRENCIES_DETAIL,
  CurrenciesForPeerToPeer,
  CurrencyShortName,
  CurrencyShortNameFiat,
  CurrencyShortNameForCustody,
  CurrencyShortNameLower,
  currencyToLower,
  getNetworkFromCurrency,
  isCurrency,
  isDepositDisabled,
  isErc20Token,
  isFiatCurrency,
  isSplToken,
  orderCurrencies,
} from "@gemini-common/scripts/constants/currencies";
import {
  EVENTS,
  optimizelyClient,
  track,
  trackBingEvent,
  trackGTMEvent,
  trackRedditEvent,
  trackTikTokEvent,
  trackTwitterEvent,
} from "@gemini-ui/analytics";
import {
  BingEvents,
  GoogleAnalyticsEvents,
  GoogleTagManagerEvents,
  RedditEvents,
  TikTokEvents,
  TwitterEvents,
} from "@gemini-ui/analytics/constants/events";
import { trackGoogleAnalyticsEvent } from "@gemini-ui/analytics/googleTracking";
import { snapchatTrack, snapEvents } from "@gemini-ui/analytics/snapchatTracking";
import { PaymentMethodIcon } from "@gemini-ui/components/Icons/PaymentMethod";
import { SiteLockout } from "@gemini-ui/components/Lockout/utils";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { PaymentMethodName } from "@gemini-ui/components/PaymentMethodName";
import { Balances, RiskBalances } from "@gemini-ui/constants/balances";
import { FEATURE_FLAG_VARIABLES, OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { NonWithdrawablePaymentMethods, PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { GeminiAccount, GeminiEntities, GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { Badge, Colors, Flex, Spacer, Text } from "@gemini-ui/design-system";
import { DefaultDropdownItemProps, GroupedDropdownItemProps } from "@gemini-ui/design-system/Dropdown/constants";
import { LimitTextLength } from "@gemini-ui/design-system/utils/LimitTextLength";
import {
  LimitType,
  TRANSFER_MECHANISM,
  TransferAccount,
  TransferMechanism,
  TransferPanelProps,
} from "@gemini-ui/pages/transfers/constants";
import { getDepositMechanisms, getTransferLabel } from "@gemini-ui/pages/transfers/utils/transferMethods";
import { checkUnsupported } from "@gemini-ui/pages/transfers/Withdraw/WithdrawDestinationSelection/utils";
import { BankAccountTransferLevel, PaymentMethodDataType } from "@gemini-ui/transformers/PaymentMethods";
import { getCookieSettings } from "@gemini-ui/utils/cookie";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

const { ach, wire, sen, blinc, xfers, bcolo, rtp, cbit } = TRANSFER_MECHANISM;

type AccountDropdownOptionProps = {
  account: TransferAccount;
  currency: CurrencyShortName;
  exchangeOnlyAssets?: CurrencyShortName[];
};

export const orderAssets = orderCurrencies;

const ERC_SUFFIX = "(ERC-20)";
const SPL_SUFFIX = "(SPL)";

const getSuffix = currency => {
  let suffix = "";

  if (isErc20Token(currency)) {
    suffix = ERC_SUFFIX;
  } else if (isSplToken(currency)) {
    suffix = SPL_SUFFIX;
  } else {
    const network = getNetworkFromCurrency(currency);
    if (network) suffix = `(${network} Network)`;
  }

  if (!suffix) suffix = `- ${CURRENCIES_DETAIL[currency].name}`;
  return suffix;
};

export const formatCurrencyDropdownItems = (
  items: CurrencyShortName[],
  intl: IntlShape,
  isDepositPage = false
): GroupedDropdownItemProps<CurrencyShortName>[] => {
  if (!items || items.length === 0)
    return [{ label: intl.formatMessage({ defaultMessage: "No currencies available" }), options: [] }];
  return items.reduce(
    (acc, curr) => {
      const suffix = getSuffix(curr);
      const option = {
        value: curr,
        label: curr + " " + suffix,
      };

      const isDisabled = isDepositDisabled(curr) && isDepositPage;
      if (!isDisabled) {
        if (isFiatCurrency(curr)) {
          acc[0].options.push(option);
        } else {
          if (acc.length <= 1) {
            acc.push({
              label: intl.formatMessage({ defaultMessage: "Cryptocurrencies" }),
              options: [],
            });
          }
          acc[1].options.push(option);
        }
      }
      return acc;
    },
    [{ label: intl.formatMessage({ defaultMessage: "Fiat currencies" }), options: [] }]
  );
};

// created a new method to handle the new grouping of currencies, it can be resued the same way formatCurrencyDropdownItems is used
export const formatTransferCurrencyDropdownItems = (
  items: CurrencyShortName[],
  intl: IntlShape,
  transferIntoPerps = false,
  isDepositPage = false
): GroupedDropdownItemProps<CurrencyShortName>[] => {
  if (!items || items.length === 0)
    return [{ label: intl.formatMessage({ defaultMessage: "No currencies available" }), options: [] }];

  if (transferIntoPerps) {
    const fundingOptions: DefaultDropdownItemProps<CurrencyShortName>[] = [];
    const crossCollateralOptions: DefaultDropdownItemProps<CurrencyShortName>[] = [];
    const cryptosOptions: DefaultDropdownItemProps<CurrencyShortName>[] = [];

    items.forEach(curr => {
      const suffix = getSuffix(curr);
      const option: DefaultDropdownItemProps<CurrencyShortName> = {
        value: curr,
        label: curr + " " + suffix,
      };

      const isDisabled = isDepositPage && isDepositDisabled(curr);

      if (!isDisabled) {
        if (
          curr === CURRENCIES_DETAIL.SGD.symbol ||
          curr === CURRENCIES_DETAIL.USD.symbol ||
          curr === CURRENCIES_DETAIL.GUSD.symbol ||
          curr === CURRENCIES_DETAIL.USDC.symbol
        ) {
          fundingOptions.push(option);
        } else if (curr === CURRENCIES_DETAIL.BTC.symbol) {
          crossCollateralOptions.push(option);
        } else {
          cryptosOptions.push(option);
        }
      }
    });

    const formattedItems: GroupedDropdownItemProps<CurrencyShortName>[] = [];

    if (fundingOptions.length > 0) {
      formattedItems.push({
        label: intl.formatMessage({ defaultMessage: "Funding" }),
        options: fundingOptions,
      });
    }

    if (crossCollateralOptions.length > 0) {
      formattedItems.push({
        label: intl.formatMessage({ defaultMessage: "Cross-collateral" }),
        options: crossCollateralOptions,
      });
    }

    if (cryptosOptions.length > 0) {
      formattedItems.push({
        label: intl.formatMessage({ defaultMessage: "Cryptos" }),
        options: cryptosOptions,
      });
    }

    return formattedItems;
  } else {
    return formatCurrencyDropdownItems(items, intl, isDepositPage);
  }
};

export const getAccountBalance = (
  account?: TransferAccount,
  currency?: CurrencyShortName | CurrenciesForPeerToPeer
): Balances => {
  if (!account?.balances || !currency || !account?.balances[currencyToLower(currency)]) return undefined;

  const balance = account.balances[currencyToLower(currency)];

  return balance;
};

export const getPerpAccountBalance = (
  account?: TransferAccount,
  currency?: CurrencyShortName | CurrenciesForPeerToPeer
): RiskBalances => {
  if (!account?.riskBalances || !currency || !account?.riskBalances[currencyToLower(currency)]) return undefined;

  const balance = account.riskBalances[currencyToLower(currency)];

  return balance;
};

export const getExchangeAccounts = (accounts: TransferAccount[]) => accounts.filter(account => !account.coldStorage);

export const getCurrenciesWithBalance = (
  currencies: CurrencyShortName[],
  accounts: TransferAccount[]
): CurrencyShortName[] => {
  return currencies.filter(currency => {
    return accounts.find(account => getAccountBalance(account, currency)?.hasBalanceHistory);
  });
};

export const getAvailableForWithdrawal = (
  source?: TransferAccount,
  currency?: CurrencyShortName | CurrenciesForPeerToPeer
): number => {
  const balance = getAccountBalance(source, currency);
  if (!balance) return 0;

  return Number(balance.availableForWithdrawal.value);
};

export const getAvailableforPerpWithdrawal = (
  source?: TransferAccount,
  currency?: CurrencyShortName | CurrenciesForPeerToPeer
): number => {
  const balance = getPerpAccountBalance(source, currency);
  if (!balance) return getAvailableForWithdrawal(source, currency);

  return Number(balance.availableWithdrawalBalance.value);
};

export const SubAccountDropdownItem = ({ name, balance }: { name: string; balance: Balances }) => (
  <Flex alignItems="center" justifyContent="space-between" flex={1}>
    <LimitTextLength text={name} addParentheses={false} finalTextLength={16} addEllipsis={name.length > 16} />{" "}
    {balance && (
      <Text.Body ml={1} color={Colors.gray[600]}>
        <Money
          data-testid="sub-account-dropdown-option-balance"
          {...balance.availableForWithdrawal}
          hideTrailingSign={isFiatCurrency(balance.availableForWithdrawal.currency)}
        />
      </Text.Body>
    )}
  </Flex>
);

export const getAccountWithBalanceDropdownItems = (
  accounts: TransferAccount[],
  currency?: CurrencyShortName | CurrenciesForPeerToPeer
): { value: string; label: ReactNode; selectedLabel: string }[] => {
  if (!currency || !accounts) return [];

  return accounts.reduce((dropdownItems, account) => {
    const { name } = account;
    const balance = getAccountBalance(account, currency);
    if (!balance) return dropdownItems;

    return dropdownItems.concat([
      { value: name, selectedLabel: name, label: <SubAccountDropdownItem balance={balance} name={name} /> },
    ]);
  }, []);
};

export const getAccountDropdownOption = ({ account, currency, exchangeOnlyAssets }: AccountDropdownOptionProps) => {
  const { coldStorage, name } = account;
  const isAccountDisabled = coldStorage && exchangeOnlyAssets?.includes(currency);
  return {
    value: name,
    label: <SubAccountDropdownItem balance={getAccountBalance(account, currency)} name={account.name} />,
    isDisabled: isAccountDisabled,
    selectedLabel: name,
    ...account,
  };
};

export const getAccountDropdownItems = (
  accounts: TransferAccount[],
  currency?: CurrencyShortName | CurrenciesForPeerToPeer,
  exchangeOnlyAssets?: CurrencyShortName[]
): DefaultDropdownItemProps[] => {
  if (!currency || accounts.length === 0) {
    return [];
  }

  return accounts.map(account => getAccountDropdownOption({ account, currency, exchangeOnlyAssets }));
};

export function filterExchangeOnlyCrypto(
  accounts: TransferAccount[],
  currency: CurrencyShortName | CurrenciesForPeerToPeer,
  exchangeOnlyAssets: CurrencyShortName[]
) {
  if (!currency || accounts.length === 0) {
    return [];
  }

  return exchangeOnlyAssets.includes(currency) ? accounts.filter(account => !account.coldStorage) : accounts;
}

export const isCustodyOnlyCrypto = (
  currency: CurrencyShortName | CurrencyShortNameLower,
  supportedExchangeCrypto: GeminiAccount["supportedExchangeCrypto"]
): boolean => {
  if (!currency) return false;
  // not a fiat and not in supportedExchangeCrypto array, currency is custody only (non tradable)
  const isFiat = isFiatCurrency(currency.toUpperCase());
  const isExchangeSupportedCrypto = supportedExchangeCrypto.includes(
    currency.toUpperCase() as CurrencyShortNameForCustody
  );
  return !isFiat && !isExchangeSupportedCrypto;
};

export const getIsExchangeOnlyCrypto = (
  currency: CurrencyShortName | CurrencyShortNameLower,
  exchangeOnlyAssets: CurrencyShortName[]
) => {
  if (!currency) return false;
  const isFiat = isFiatCurrency(currency.toUpperCase());
  return !isFiat && exchangeOnlyAssets.includes(currency.toUpperCase() as CurrencyShortName);
};

export function filterBankAccounts(
  paymentMethods: Array<PaymentMethodDataType>,
  transferMechanism: TransferMechanism,
  intl: IntlShape
): Array<PaymentMethodDataType> {
  const { bcolo, xfers, ach, sen, blinc, plaid, paypal, rtp } = getDepositMechanisms(intl);
  switch (transferMechanism) {
    case xfers.value:
      return paymentMethods.filter(method => method.paymentMethodType === PaymentMethodType.XFERS);
    case rtp.value:
      return paymentMethods.filter(
        method =>
          method.paymentMethodType === PaymentMethodType.BANK && method.rtpLevel === BankAccountTransferLevel.Approved
      );
    case bcolo.value:
      return paymentMethods.filter(method => method.paymentMethodType === PaymentMethodType.BCOLO && method.available);
    case ach.value:
    case sen.value:
    case blinc.value:
      return paymentMethods.filter(
        method =>
          method.paymentMethodType === PaymentMethodType.BANK &&
          method[`${transferMechanism}Level`] === BankAccountTransferLevel.Approved
      );
    case plaid.value:
      return paymentMethods.filter(
        method => method.paymentMethodType === PaymentMethodType.BANK && method.supportsPlaidPaymentInitiation
      );
    case paypal.value:
      return paymentMethods.filter(method => method.paymentMethodType === PaymentMethodType.PAYPAL);
    default:
      return paymentMethods;
  }
}

export const filterAccountsByCurrency = (
  isFiat: boolean,
  accounts: TransferAccount[],
  accountHashId: string,
  currency: CurrencyShortName,
  supportedExchangeCrypto: GeminiAccount["supportedExchangeCrypto"]
): TransferAccount[] => {
  if (isFiat) {
    return accounts.filter(account => account.hashid === accountHashId);
  }

  if (isCustodyOnlyCrypto(currency, supportedExchangeCrypto)) {
    // is custody account?
    return accounts.filter(account => account.coldStorage && !account.trading);
  }

  return accounts;
};

export const getDestinationFromAccounts = (
  accounts: TransferAccount[],
  destination: TransferAccount
): TransferAccount => {
  return accounts.find(account => account.hashid === destination?.hashid);
};

interface Permissions {
  transactionsEnabled: boolean;
  canAddAddress: boolean;
  canDepositCrypto: boolean;
  canDepositFiatAch: boolean;
  canDepositFiatWire: boolean;
  canDepositFiatXfers: boolean;
  canDepositFiatBcolo: boolean;
  canDepositFiatPlaidPayment: boolean;
  canDepositFiatPayPal: boolean;
  canDepositFiatRtp: boolean;
}

export const getPermissions = (
  props: TransferPanelProps,
  isFiat: boolean,
  currency: CurrencyShortName,
  lockout: SiteLockout
): Permissions => {
  let canDepositCrypto = false;
  let canDepositFiatAch = false;
  let canDepositFiatWire = false;
  let canDepositFiatXfers = false;
  let canDepositFiatBcolo = false;
  let canDepositFiatPlaidPayment = false;
  let canDepositFiatPayPal = false;
  let canDepositFiatRtp = false;
  const transactionsEnabled = currency ? _.get(props, `transactionsEnabled.${currency}`, true) : true;
  const canAddAddress = currency ? _.get(props, `canAddAddress.${currency}`, false) : false;

  // @multiFiatAware
  if (currency && isFiat) {
    canDepositFiatAch = _.get(props.canDepositFiat, `${currency}.AchTransfer`, false);
    canDepositFiatWire = _.get(props.canDepositFiat, `${currency}.WireTransfer`, false);
    canDepositFiatXfers = _.get(props.canDepositFiat, `${currency}.XfersTransfer`, false);
    canDepositFiatBcolo = _.get(props.canDepositFiat, `${currency}.BcoloTransfer`, false);
    canDepositFiatPlaidPayment = _.get(props.canDepositFiat, `${currency}.PlaidPaymentInitiation`, false);
    canDepositFiatPayPal = _.get(props.canDepositFiat, `${currency}.PayPalTransfer`, false);
    canDepositFiatRtp = _.get(props.canDepositFiat, `${currency}.RtpTransfer`, false);

    // overriding canDepositFiatWire permission
    if (lockout === "wireDepositForVerification") {
      canDepositFiatWire = true;
    }
  } else if (currency) {
    canDepositCrypto = props.docsApproved;
  }

  return {
    transactionsEnabled,
    canAddAddress,
    canDepositCrypto,
    canDepositFiatAch,
    canDepositFiatWire,
    canDepositFiatXfers,
    canDepositFiatBcolo,
    canDepositFiatPlaidPayment,
    canDepositFiatPayPal,
    canDepositFiatRtp,
  };
};

export const TransferMethodLabel = ({
  paymentMethodName,
  accountName,
  isMultiAccount,
}: {
  paymentMethodName: ReactNode;
  isMultiAccount?: boolean;
  accountName?: string;
}) => {
  return (
    <div>
      <Flex pt={1} pb={1} flexDirection="column" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        <Text.Body size="sm">{paymentMethodName}</Text.Body>
        {isMultiAccount && accountName && (
          <Text.Body size="xs" color={Colors.gray[600]}>
            {accountName}
          </Text.Body>
        )}
      </Flex>
    </div>
  );
};

export const getPaymentMethodWithAccountNameDropdownItems = (
  accounts: TransferAccount[],
  paymentMethods: PaymentMethodDataType[],
  subaccountHashId: string,
  isMultiAccount: boolean
): DefaultDropdownItemProps[] => {
  if (!paymentMethods) return [];

  const selectedAccount = accounts.find(acct => acct.hashid === subaccountHashId);
  const accountName = selectedAccount ? selectedAccount.name : "";

  return paymentMethods.map(paymentMethod => {
    const { id, paymentMethodType, lastFour, displayName, institutionName } = paymentMethod;
    const accountType =
      paymentMethodType !== PaymentMethodType.DEBIT_CARD && paymentMethodType !== PaymentMethodType.PAYPAL
        ? paymentMethod.accountType
        : undefined;
    return {
      icon: <PaymentMethodIcon institutionName={institutionName} paymentMethodType={paymentMethodType} />,
      label: (
        <TransferMethodLabel
          paymentMethodName={
            <PaymentMethodName {...{ lastFour, rawName: displayName, institutionName, accountType }} />
          }
          accountName={accountName}
          isMultiAccount={isMultiAccount}
        />
      ),
      value: id,
    };
  });
};

export const serializeBankAndTransferMechanismValue = (transferMechanism: TransferMechanism, bankUuid: string) => {
  return `${transferMechanism}|${bankUuid}`;
};

export const deserializeBankAndTransferMechanismValue = (bankAndTransferMechanismValue: string) => {
  return bankAndTransferMechanismValue.split("|") as [TransferMechanism, string];
};

const TransferMechanismGroupLabel = ({
  name,
  withdrawalLimit,
  withdrawalMessage = "",
}: {
  name: ReactNode;
  withdrawalLimit?: LimitType;
  withdrawalMessage?: ReactNode;
}) => {
  const { intl } = useIntl();

  return (
    <Spacer mb={1.5}>
      <Text.Body size="xs" bold>
        {name}
      </Text.Body>
      {withdrawalLimit?.portion?.value ? (
        <Text.Body size="xs" color={Colors.gray[600]}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "<MoneyWithdrawalLimitPortion></MoneyWithdrawalLimitPortion> Remaining daily limit",
            }),
            {
              MoneyWithdrawalLimitPortion: () => <Money {...withdrawalLimit?.portion} />,
            }
          )}
        </Text.Body>
      ) : (
        withdrawalMessage
      )}
    </Spacer>
  );
};

const BADGE_COLOR = "#FFF8E3";

const getTransferMethodOption = (
  paymentMethod: PaymentMethodDataType,
  showUnverifiedBadge: boolean,
  value: string,
  intl: IntlShape
) => {
  const { paymentMethodType, lastFour, displayName, institutionName } = paymentMethod;
  const accountType =
    paymentMethodType !== PaymentMethodType.DEBIT_CARD && paymentMethodType !== PaymentMethodType.PAYPAL
      ? paymentMethod.accountType
      : undefined;
  const cardIssuer = paymentMethodType === PaymentMethodType.DEBIT_CARD ? paymentMethod.cardIssuer : undefined;

  return {
    icon: (
      <PaymentMethodIcon
        institutionName={institutionName}
        paymentMethodType={paymentMethodType}
        cardIssuer={cardIssuer}
      />
    ),
    isDisabled: NonWithdrawablePaymentMethods.includes(paymentMethodType) || showUnverifiedBadge,
    value,
    subLabel: showUnverifiedBadge ? (
      <Badge
        backgroundColor={BADGE_COLOR}
        icon={<IconInfoFilled size="sm" color={Colors.yellow[500]} />}
        data-testid="unverified-bank-badge"
        variant="flat"
      >
        <Text.Body size="xs">{intl.formatMessage({ defaultMessage: "Unverified" })}</Text.Body>
      </Badge>
    ) : null,
    label: (
      <TransferMethodLabel
        paymentMethodName={
          <PaymentMethodName
            {...{
              lastFour,
              rawName: paymentMethodType === PaymentMethodType.CBIT ? paymentMethod.walletName : displayName,
              institutionName,
              accountType,
            }}
          />
        }
      />
    ),
  };
};

export const getWithdrawalTransferMechanismsFromPaymentMethod = (paymentMethod: PaymentMethodDataType | null) => {
  // Order of these if statements matter as we order them from left to right, fees -> no fees
  if (!paymentMethod) return [];
  if (paymentMethod.paymentMethodType === PaymentMethodType.XFERS) {
    return [xfers];
  }

  if (paymentMethod.paymentMethodType === PaymentMethodType.BANK) {
    const transferMechanisms: TransferMechanism[] = [];

    if (paymentMethod.achLevel === BankAccountTransferLevel.Approved) {
      transferMechanisms.push(ach);
    }

    if (paymentMethod.wireLevel === BankAccountTransferLevel.Approved) {
      transferMechanisms.push(wire);
    }

    if (paymentMethod.rtpLevel === BankAccountTransferLevel.Approved) {
      transferMechanisms.push(rtp);
    }

    if (paymentMethod.senLevel === BankAccountTransferLevel.Approved) {
      transferMechanisms.push(sen);
    }

    if (paymentMethod.blincLevel === BankAccountTransferLevel.Approved) {
      transferMechanisms.push(blinc);
    }
    return transferMechanisms;
  }

  if (paymentMethod.paymentMethodType === PaymentMethodType.BCOLO) {
    return [bcolo];
  }

  if (paymentMethod.paymentMethodType === PaymentMethodType.CBIT) {
    return [cbit];
  }

  return [];
};

// Destination selection when wire fees feature is on https://gemini-spaceship.atlassian.net/browse/GEM-54564
export const getPaymentMethodDestinationItems = (
  paymentMethods: PaymentMethodDataType[],
  intl: IntlShape
): GroupedDropdownItemProps[] => {
  const debitCards = {
    label: <DebitCardGroupLabel />,
    options: [],
  };

  const payPal = {
    label: (
      <TransferMechanismGroupLabel
        name={intl.formatMessage({ defaultMessage: "PayPal" })}
        withdrawalMessage={
          <Text.Body size="xs" color={Colors.red[600]}>
            {intl.formatMessage({
              defaultMessage: "Cannot withdraw to PayPal accounts",
            })}
          </Text.Body>
        }
      />
    ),
    options: [],
  };

  const banks = {
    label: <TransferMechanismGroupLabel name={intl.formatMessage({ defaultMessage: "Bank account" })} />,
    options: [],
  };

  const cbit = {
    label: <TransferMechanismGroupLabel name={intl.formatMessage({ defaultMessage: "CBIT account" })} />,
    options: [],
  };

  paymentMethods.forEach(paymentMethod => {
    const { id, paymentMethodType, isVerified } = paymentMethod;
    if (
      paymentMethodType === PaymentMethodType.BANK ||
      paymentMethodType === PaymentMethodType.BCOLO ||
      paymentMethodType === PaymentMethodType.XFERS
    ) {
      banks.options.push(getTransferMethodOption(paymentMethod, !isVerified, id, intl));
    } else if (paymentMethodType === PaymentMethodType.PAYPAL) {
      payPal.options.push(getTransferMethodOption(paymentMethod, true, id, intl));
    } else if (paymentMethodType === PaymentMethodType.CBIT) {
      cbit.options.push(getTransferMethodOption(paymentMethod, !isVerified, id, intl));
    } else if (paymentMethodType === PaymentMethodType.DEBIT_CARD) {
      debitCards.options.push(getTransferMethodOption(paymentMethod, !isVerified, id, intl));
    }
  });

  banks.options.sort(a => (a.isDisabled ? 1 : -1));

  // @ts-expect-error
  return [banks, cbit, payPal, debitCards].filter(transferMethod => transferMethod.options.length > 0);
};

const DebitCardGroupLabel = () => {
  const { intl } = useIntl();
  return (
    <TransferMechanismGroupLabel
      name="Debit card"
      withdrawalMessage={
        <Text.Body size="xs" color={Colors.red[600]}>
          {intl.formatMessage({
            defaultMessage: "Cannot withdraw to debit cards",
          })}
        </Text.Body>
      }
    />
  );
};

// Destination selection when wire fees feature is off https://gemini-spaceship.atlassian.net/browse/GEM-54564
export const getTransferMechanismDestinationItems = (
  paymentMethods: PaymentMethodDataType[],
  dailyWithdrawalLimit: LimitType,
  xfersWithdrawalLimit: MoneyProps,
  rtpWithdrawalLimit: MoneyProps,
  currency: CurrencyShortName,
  intl: IntlShape,
  bcoloThirtyDayWithdrawalLimit?: LimitType
): GroupedDropdownItemProps[] => {
  if (!paymentMethods) return [];

  const debitCards = {
    label: <DebitCardGroupLabel />,
    options: [],
  };

  const achBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(ach, intl, currency)}
        withdrawalLimit={dailyWithdrawalLimit}
      />
    ),
    options: [],
  };
  const wireBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(wire, intl, currency)}
        withdrawalMessage={
          <Text.Body size="xs" color={Colors.gray[600]}>
            {intl.formatMessage({
              defaultMessage: "No limit",
            })}
          </Text.Body>
        }
      />
    ),
    options: [],
  };
  const senBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(sen, intl, currency)}
        withdrawalMessage={
          <Text.Body size="xs" color={Colors.gray[600]}>
            {intl.formatMessage({
              defaultMessage: "No limit",
            })}
          </Text.Body>
        }
      />
    ),
    options: [],
  };
  const blincBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(blinc, intl, currency)}
        withdrawalMessage={
          <Text.Body size="xs" color={Colors.gray[600]}>
            {intl.formatMessage({
              defaultMessage: "No limit",
            })}
          </Text.Body>
        }
      />
    ),
    options: [],
  };
  const xfersBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(xfers, intl, currency)}
        withdrawalMessage={
          xfersWithdrawalLimit && (
            <Text.Body size="xs" color={Colors.gray[600]}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "{MoneyComponent} limit per withdrawal",
                }),
                {
                  MoneyComponent: <Money {...xfersWithdrawalLimit} decimalsOverride={0} />,
                }
              )}
            </Text.Body>
          )
        }
      />
    ),
    options: [],
  };
  const rtpBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel("rtp", intl, currency)}
        withdrawalMessage={
          rtpWithdrawalLimit && (
            <Text.Body size="xs" color={Colors.gray[600]}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "{MoneyComponent} limit per withdrawal",
                }),
                {
                  MoneyComponent: <Money {...rtpWithdrawalLimit} decimalsOverride={0} />,
                }
              )}
            </Text.Body>
          )
        }
      />
    ),
    options: [],
  };
  const bcoloBanks = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(bcolo, intl, currency)}
        withdrawalMessage={
          bcoloThirtyDayWithdrawalLimit && (
            <Text.Body size="xs" color={Colors.gray[600]}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "{MoneyComponent} Remaining 30 day limit",
                }),
                {
                  MoneyComponent: <Money {...bcoloThirtyDayWithdrawalLimit.portion} decimalsOverride={0} />,
                }
              )}
            </Text.Body>
          )
        }
      />
    ),
    options: [],
  };
  const cbitAccounts = {
    label: (
      <TransferMechanismGroupLabel
        name={getTransferLabel(cbit, intl, currency)}
        withdrawalMessage={
          <Text.Body size="xs" color={Colors.gray[600]}>
            {intl.formatMessage({
              defaultMessage: "No limit",
            })}
          </Text.Body>
        }
      />
    ),
    options: [],
  };

  paymentMethods.forEach(paymentMethod => {
    const { id, paymentMethodType, currencies, isVerified } = paymentMethod;

    // Show users bank accounts that are unverified so they know why they cant withdraw
    if (
      paymentMethodType === PaymentMethodType.BANK &&
      currencies.includes(CURRENCIES_DETAIL.USD.symbol) &&
      (!isVerified || paymentMethod.achLevel === BankAccountTransferLevel.Approved)
    ) {
      achBanks.options.push(
        getTransferMethodOption(paymentMethod, !isVerified, serializeBankAndTransferMechanismValue(ach, id), intl)
      );
    }
    // Show users bank accounts that are unverified so they know why they cant withdraw
    if (
      paymentMethodType === PaymentMethodType.BANK &&
      (!isVerified || paymentMethod.wireLevel === BankAccountTransferLevel.Approved)
    ) {
      wireBanks.options.push(
        getTransferMethodOption(paymentMethod, !isVerified, serializeBankAndTransferMechanismValue(wire, id), intl)
      );
    }
    if (paymentMethodType === PaymentMethodType.BANK && paymentMethod.senLevel === BankAccountTransferLevel.Approved) {
      senBanks.options.push(
        getTransferMethodOption(paymentMethod, false, serializeBankAndTransferMechanismValue(sen, id), intl)
      );
    }
    if (
      paymentMethodType === PaymentMethodType.BANK &&
      paymentMethod.blincLevel === BankAccountTransferLevel.Approved
    ) {
      blincBanks.options.push(
        getTransferMethodOption(paymentMethod, false, serializeBankAndTransferMechanismValue(blinc, id), intl)
      );
    }

    if (paymentMethodType === PaymentMethodType.CBIT) {
      cbitAccounts.options.push(
        getTransferMethodOption(paymentMethod, !isVerified, serializeBankAndTransferMechanismValue(cbit, id), intl)
      );
    }

    if (paymentMethodType === PaymentMethodType.XFERS) {
      xfersBanks.options.push(
        getTransferMethodOption(paymentMethod, !isVerified, serializeBankAndTransferMechanismValue(xfers, id), intl)
      );
    }

    if (
      paymentMethodType === PaymentMethodType.BANK &&
      paymentMethod.rtpLevel === BankAccountTransferLevel.Approved &&
      currencies.includes(CURRENCIES_DETAIL.SGD.symbol)
    ) {
      rtpBanks.options.push(
        getTransferMethodOption(paymentMethod, !isVerified, serializeBankAndTransferMechanismValue(rtp, id), intl)
      );
    }

    if (
      paymentMethodType === PaymentMethodType.BCOLO &&
      currencies.includes(CURRENCIES_DETAIL.COP.symbol) &&
      paymentMethod.available
    ) {
      bcoloBanks.options.push(
        getTransferMethodOption(paymentMethod, !isVerified, serializeBankAndTransferMechanismValue(bcolo, id), intl)
      );
    }
    if (paymentMethodType === PaymentMethodType.DEBIT_CARD) {
      debitCards.options.push(getTransferMethodOption(paymentMethod, !isVerified, id, intl));
    }
  });

  achBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  wireBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  senBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  blincBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  xfersBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  rtpBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  bcoloBanks.options.sort(a => (a.isDisabled ? 1 : -1));
  cbitAccounts.options.sort(a => (a.isDisabled ? 1 : -1));

  // @ts-expect-error
  return [achBanks, wireBanks, cbitAccounts, senBanks, blincBanks, rtpBanks, xfersBanks, bcoloBanks, debitCards].filter(
    transferMethod => transferMethod.options.length > 0
  );
};

export const isExchangeAccount = (account: TransferAccount) => !account.coldStorage && account.trading;

export const emptySelectedCurrency = (currency: CurrencyShortName): Balances => ({
  assetTotal: { currency, value: "0" },
  total: { currency, value: "0" },
  openOrdersCount: 0,
  openOrdersSum: { currency, value: 0 },
  advancesCount: 0,
  advancesSum: { currency, value: 0 },
  availableForWithdrawal: { currency, value: 0 },
  availableForTrading: { currency, value: 0 },
  totalNotionalValue: { currency, value: 0 } as MoneyProps<CurrencyShortNameFiat>,
  notionalValue: { currency, value: 0 } as MoneyProps<CurrencyShortNameFiat>,
  openWithdrawalsSum: { currency, value: 0 },
  openWithdrawalsCount: 0,
});

export const trackDepositSuccessAnalytics = (amount: string, currency: CurrencyShortNameFiat) => {
  try {
    // DEPOSIT SUCCESSFUL - NEW DEPOSIT ANALYTICS WE ARE USING GOING FORWARD
    const { DEPOSIT_SUCCESSFUL, DEPOSIT_FUNDS } = EVENTS;
    const { properties, name } = DEPOSIT_FUNDS;
    track(DEPOSIT_SUCCESSFUL.name);
    track(
      name,
      {
        [properties.AMOUNT]: amount,
        [properties.CURRENCY]: currency,
      },
      { braze: true }
    );
    // all with not be initialized except for roku which is fired adhoc
    if (!getCookieSettings().isSuppressed) {
      trackGoogleAnalyticsEvent(GoogleAnalyticsEvents.DepositFunds);
      snapchatTrack(snapEvents.depositFunds);
      trackTwitterEvent(TwitterEvents.DepositFunds);
      trackRedditEvent(RedditEvents.DepositFunds);
      trackBingEvent(BingEvents.DepositFunds);
      trackTikTokEvent(TikTokEvents.DepositFunds);
      trackGTMEvent(GoogleTagManagerEvents.DEPOSIT);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

type TransferDisabledArgs = {
  currency: CurrencyShortName;
  supportedFiat: CurrencyShortName[];
  exchangeOnlyAssets: CurrencyShortName[];
  accounts: TransferAccount[];
  source: TransferAccount;
  canTransfer: boolean;
  inOberonOperatingArea: boolean;
  supportedExchangeCrypto: GeminiAccount["supportedExchangeCrypto"];
};

export function getTransferDisabledValues({
  currency,
  supportedFiat,
  exchangeOnlyAssets,
  accounts,
  source,
  canTransfer,
  inOberonOperatingArea,
  supportedExchangeCrypto,
}: TransferDisabledArgs) {
  const isExchangeCurrency = supportedFiat.includes(currency) || exchangeOnlyAssets.includes(currency);
  const filteredAccounts = isExchangeCurrency ? accounts.filter(account => !account.coldStorage) : accounts;
  const availableForWithdrawal = getAvailableForWithdrawal(source, currency);
  const isExchange = source && !source.coldStorage && source.trading;
  const gusdDisabledForAccount = isCurrency.GUSD(currency) && (!canTransfer || !inOberonOperatingArea) && isExchange;
  const isCustodyAccount = source && source.coldStorage;
  const isInvalidErc20Destination = isCustodyOnlyCrypto(currency, supportedExchangeCrypto) && !isCustodyAccount;
  const isExchangeOnlyCrypto = getIsExchangeOnlyCrypto(currency, exchangeOnlyAssets);

  return {
    isExchangeCurrency,
    filteredAccounts,
    availableForWithdrawal,
    gusdDisabledForAccount,
    isCustodyAccount,
    isInvalidErc20Destination,
    isExchangeOnlyCrypto,
  };
}

export const getIsGeminiUk = (geminiEntity: GeminiEntity): boolean =>
  (
    [
      GeminiEntities.GeminiEurope,
      GeminiEntities.GeminiEuropeEmployees,
      GeminiEntities.GeminiEuropeInstitutional,
    ] as const
  ).includes(geminiEntity);

export const getIsUkInboundEnabled = (geminiEntity: GeminiEntity): boolean => {
  return getIsGeminiUk(geminiEntity);
};

export const getIsUnsupportedAsset = (currency: CurrencyShortName) => {
  const unsupportedAssets = optimizelyClient.getFeatureVariableJSON(
    OPTIMIZELY_FEATURE_FLAGS.WEB_UK_OUTBOUND_TRAVEL_RULE,
    FEATURE_FLAG_VARIABLES.WEB_UK_OUTBOUND_TRAVEL_RULE.UNSUPPORTED_TOKENS
  ); // we can use the same variable from outbound flag

  return unsupportedAssets?.tokens && checkUnsupported(unsupportedAssets, currency);
};

/**
 * Conditionally roll a new UUID for the transferId in a transfers reducer.
 *
 * See diagram for details on how transferIds should used:
 * https://lucid.app/lucidspark/5370c0e3-c5f6-4339-8704-df0067b68fb3/edit?invitationId=inv_17cc6509-9da0-49c7-8d04-aab7c6301684&page=0_0#
 *
 * @param {Boolean} shouldRoll Whether to create a new transferId
 * @returns {Object | null} Returns new transferId state
 */
export const rollTransferIdState = (shouldRoll: boolean): { transferId: string } | null => {
  return shouldRoll ? { transferId: uuidv4() } : null;
};
