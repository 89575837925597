import * as Sentry from "@sentry/browser";

type EventCallback = (...args: any[]) => void;

class EventEmitter {
  private events: Record<string, EventCallback[]> = {};

  on(event: string, callback: EventCallback): void {
    try {
      if (!this.events[event]) {
        this.events[event] = [];
      }

      this.events[event]?.push(callback);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  off(event: string, callback?: EventCallback): void {
    try {
      const eventCallbacks = this.events[event];
      if (eventCallbacks) {
        if (!callback) {
          delete this.events[event];
        } else {
          this.events[event] = eventCallbacks.filter(cb => cb !== callback);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  emit(event: string, ...args: any[]): void {
    try {
      const eventCallbacks = this.events[event];
      if (eventCallbacks) {
        eventCallbacks.forEach(callback => callback(...args));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

const eventEmitter = new EventEmitter();

export default eventEmitter;
