import { useActivityTimeout } from "@gemini-ui/components/ActivityTimeoutHandler/hooks";
import { Button, Modal, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

const signOutUrl = jsRoutes.controllers.account.Application.signOut().url;

const ActivityTimeoutHandler = () => {
  const { intl } = useIntl();

  const { handlePing, isOpen } = useActivityTimeout();

  return (
    <Modal
      isOpen={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Session About to Expire",
      })}
    >
      <Text mb={3}>
        {intl.formatMessage({
          defaultMessage:
            "You have been inactive for a while, and will be automatically signed out soon. Are you still there?",
        })}
      </Text>
      <Button.Group type="action">
        <Button.Tertiary data-testid="SessionExpire-SignOut" href={signOutUrl}>
          {intl.formatMessage({
            defaultMessage: "Sign Out",
          })}
        </Button.Tertiary>
        <Button.Primary data-testid="SessionExpire-Ping" onClick={handlePing}>
          {intl.formatMessage({
            defaultMessage: "I'm Still Here",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

export default ActivityTimeoutHandler;
