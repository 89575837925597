import { ReactNode } from "react";
import styled from "@emotion/styled";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { Colors, getColor, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const CopyrightContainer = styled.div`
  ul > li {
    font-size: 9px;
    color: ${getColor(Colors.gray[600], Colors.gray[200])};
  }
`;

const Copyright = () => {
  const { intl, buildGeminiPublicLink } = useIntl();
  const {
    templateProps: { user },
  } = usePageData();
  const artemis = user?.subaccounts.find(f => f.hashid === user?.subaccountHashid)?.derivatives;
  const company = artemis
    ? intl.formatMessage({ defaultMessage: "Gemini Artemis Pte. Ltd." })
    : intl.formatMessage({ defaultMessage: "Gemini Trust Company, LLC." });
  const showNewCopyright = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_SHOW_NEW_COPYRIGHT);

  const year = new Date().getFullYear();

  if (showNewCopyright && !artemis) {
    return (
      <CopyrightContainer>
        <ul className="links">
          <li>
            {intl.formatMessage({
              defaultMessage: "Gemini Moonbase, LLC (NMLS # 2403509)",
            })}
          </li>
          <li>
            {intl.formatMessage({
              defaultMessage:
                "Gemini Trust Company, LLC (NMLS # 1518126) d/b/a Gemini Exchange, LLC in AZ, CA, DE, FL, ID, IL, KS, KY, MA, MI, MN, NC, ND, NM, OH, OR, SC, SD, UT, and VA; d/b/a Gemini Exchange in AK and WA",
            })}
          </li>
          <li>
            {intl.formatMessage({
              defaultMessage: "600 Third Avenue, 2nd Floor, New York, NY 10016",
            })}
          </li>
          <li>
            {intl.formatMessage(
              defineMessage({
                defaultMessage: "Copyright {year} Gemini Space Station, LLC or its affiliates. All rights reserved.",
              }),
              {
                year,
              }
            )}
          </li>
        </ul>
      </CopyrightContainer>
    );
  }

  return (
    <CopyrightContainer>
      <ul className="links">
        <li>
          {intl.formatMessage({
            defaultMessage: "Trust is Our Product™",
          })}
        </li>
        <li>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "For trademarks and patents, please see our <TextLink>Legal Notice</TextLink>.",
            }),
            {
              TextLink: (v: ReactNode) => (
                <Text.Link href={buildGeminiPublicLink("legal/legal-notice")} target="_blank" rel="noopener noreferrer">
                  {v}
                </Text.Link>
              ),
            }
          )}
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: "NMLS #1518126",
            description: "Copyright licensing system number",
          })}
        </li>
        <li>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "© Copyright {year} {company}",
            }),
            {
              year,
              company,
            }
          )}
        </li>
      </ul>
    </CopyrightContainer>
  );
};

export default Copyright;
