import { MoneyProps } from "@gemini-ui/components/Money";
import { DocumentTypes, RequestedVariant } from "@gemini-ui/components/OnfidoDocumentUploader/constants";
import { PhoneNumber } from "@gemini-ui/constants/phoneNumber";
import { CryptoTradingExperience } from "@gemini-ui/pages/register/Verify/screens/FinancialInfo/fields/cryptoTradingExperience";
import { EmploymentStatus } from "@gemini-ui/pages/register/Verify/screens/FinancialInfo/fields/employmentStatus";
import { Industry } from "@gemini-ui/pages/register/Verify/screens/FinancialInfo/fields/industry";
import { Nationality } from "@gemini-ui/pages/register/Verify/screens/FinancialInfo/fields/nationality";
import { Purpose } from "@gemini-ui/pages/register/Verify/screens/FinancialInfo/fields/purpose";
import { SourceOfWealth } from "@gemini-ui/pages/register/Verify/screens/FinancialInfo/fields/sourceOfWealth";

export interface VerifyMachineProps {
  send: (event: VerifyEvent) => void;
  user: VerifyContext;
  documentTypes: Partial<Record<DocumentTypes, boolean>>;
}

export enum UserTier {
  Registered = "Registered",
  Confirmed = "Confirmed",
  BasicPlus = "BasicPlus",
  Full = "Full",
}
/************************************************************************
 *
 * SingPass config, redacted for sending to the client
 *
 ************************************************************************/

export interface SingPassPublicConfig {
  apiContext?: string;
  apiVersion?: string;
  baseUrl?: string;
  clientId?: string;
  mobileRedirectUrl?: string;
  webRedirectUrl?: string;
  attributes?: string;
}

/************************************************************************
 *
 * Onfido Document Upload Types
 *
 ************************************************************************/

export type OnfidoDocumentSide = "front" | "back";

export interface OnfidoDocumentDetails {
  id: string;
  type: DocumentTypes;
  side: OnfidoDocumentSide;
}

export interface OnfidoFaceDetails {
  id: string;
  variant: RequestedVariant;
}

interface OnfidoPOADetails {
  id: string;
  side: OnfidoDocumentSide;
  type: DocumentTypes;
  variant: RequestedVariant;
}
export interface OnfidoDocumentResponse {
  document_front: OnfidoDocumentDetails;
  document_back?: OnfidoDocumentDetails;
  face?: OnfidoFaceDetails;
  poa?: OnfidoPOADetails;
}

export interface FilestackUploadRecord {
  container: string;
  documentType:
    | "Bill"
    | "W9W8BENe"
    | "FormationDocument"
    | "Wolfsberg"
    | "InstitutionalMiscellaneous"
    | "TrusteeSignatories"
    | "AdminQuestionnaire";
  filename: string;
  key: string;
  size: number;
  type: string;
  url: string;
}

/************************************************************************
 *
 * Machine Events
 *
 ************************************************************************/
export const VerifyEventName = {
  SUCCESS: "SUCCESS",
  UPLOAD_DOCUMENT: "SUCCESS.UPLOAD_DOCUMENT",
  UPLOAD_PASSPORT: "SUCCESS.UPLOAD_PASSPORT",
  UPLOAD_PROOF_OF_ADDRESS: "SUCCESS.UPLOAD_PROOF_OF_ADDRESS",
  FALLBACK_TO_FULL: "FALLBACK_TO_FULL",
  ENTER_MANUALLY: "ENTER_MANUALLY",
  SKIP_VERIFYING_SCREEN: "SKIP_VERIFYING_SCREEN",
} as const;

type SuccessEvent = {
  type: typeof VerifyEventName.SUCCESS;
  userData?: Partial<VerifyContext>;
};

type UploadDocumentSuccessEvent = {
  type: typeof VerifyEventName.UPLOAD_PASSPORT | typeof VerifyEventName.UPLOAD_DOCUMENT;
  userData: Partial<VerifyContext>;
};

type UploadProofOfAddressSuccessEvent = {
  type: typeof VerifyEventName.UPLOAD_PROOF_OF_ADDRESS;
  userData: Partial<VerifyContext>;
};

type FallbackToFullFlowEvent = {
  type: typeof VerifyEventName.FALLBACK_TO_FULL;
  userData: Partial<VerifyContext>;
};

type EnterManuallyEvent = {
  type: typeof VerifyEventName.ENTER_MANUALLY;
  userData: Partial<VerifyContext>;
};

type SkipVerifyingScreenEvent = {
  type: typeof VerifyEventName.SKIP_VERIFYING_SCREEN;
  userData?: Partial<VerifyContext>;
};

export type VerifyEvent =
  | SuccessEvent
  | UploadDocumentSuccessEvent
  | UploadProofOfAddressSuccessEvent
  | EnterManuallyEvent
  | FallbackToFullFlowEvent
  | SkipVerifyingScreenEvent;

/************************************************************************
 *
 * Machine Context
 *
 ************************************************************************/

interface AccountStateContext {
  tier: UserTier;
  isInitiallyBasicTier?: boolean;
}

export interface LegalName {
  firstName: string;
  middleName?: string;
  lastName: string;
}

export interface UserAddress {
  entered?: boolean;
  country: string;
  streetAddress: string;
  apt?: string;
  locality: string;
  region?: string;
  postalCode: string;
}

// TODO: We need to consolidate so that this isn't a thing any longer
// only used in test mocks
export const convertLegalIdTypeToDocumentType = (idType: LegalIdType) => {
  switch (idType) {
    case LegalIdType.Passport:
      return DocumentType.Passport;
    case LegalIdType.DriversLicense:
      return DocumentType.DriversLicense;
    case LegalIdType.NationalIdCard:
      return DocumentType.NationalIdentityCard;
    default:
      return undefined;
  }
};

export const ONFIDO_DOCUMENT_TYPE = {
  Passport: "passport",
  PassportCard: "passport_card",
  DriversLicense: "driving_licence", // onfido supports it as licence
  NationalIdentityCard: "national_identity_card",
  ResidencePermit: "residence_permit",
  TaxId: "tax_id",
  VoterId: "voter_id",
  IndigenousCard: "indigenous_card",
  NationalInsuranceCard: "national_insurance_card",
  WorkPermit: "work_permit",
  Bill: "Bill", // returned by service when country is not found
} as const;

// map to expected BE country configuration values
type OnfidoDocumentKeyType = keyof typeof ONFIDO_DOCUMENT_TYPE;

export enum LegalIdType {
  Passport = "passport",
  DriversLicense = "drivers_license",
  NationalIdCard = "national_identity_card",
  ResidencePermit = "residence_permit",
  WorkPermit = "work_permit",
  PassportCard = "passport_card",
  NationalInsuranceCard = "national_insurance_card",
  IndigenousCard = "indigenous_card",
  SSN = "ssn",
  SIN = "sin",
  TaxId = "tax_id",
}

// Maps to DocType
export enum DocumentType {
  Passport = "Passport",
  PassportCard = "PassportCard",
  DriversLicense = "DriversLicence",
  NationalIdentityCard = "NationalIdentityCard",
  ResidencePermit = "ResidencePermit",
  TaxId = "TaxId", // not yet supported by SDK
  VoterId = "VoterIdCard",
  IndigenousCard = "IndigenousCard",
  NationalInsuranceCard = "NationalInsuranceCard",
  WorkPermit = "WorkPermit",
  Id = "Id",
  Bill = "Bill",
  W9 = "W9",
  W8BENe = "W8BENe",
  Wolfsberg = "Wolfsberg",
  FormationDocument = "FormationDocument",
  InstitutionalMiscellaneous = "InstitutionalMiscellaneous",
}

// TODO: Split out the GET and POST structure
export interface DocumentUpload {
  documentType?: DocumentType;
  idNumberEntered?: boolean;
  // Used for POST
  idType?: LegalIdType;
  idNumber?: string;
  issuingCountry?: string;
  issuingState?: string;
}

interface VerifyContextFinancialInfo {
  sourceOfWealth: SourceOfWealth;
  netWorthRange: CurrencyValueRangeType;
  occupation: EmploymentStatus;
  nationality: Nationality;
  cryptoTradingExperience: CryptoTradingExperience;
  annualIncome?: CurrencyValueRangeType;
  industry?: Industry;
}

export type CurrencyValueRangeType = {
  min: MoneyProps;
  max: MoneyProps;
};

type VerifyExchangeUsage = {
  purpose: Purpose;
  expectedTradingAmount: CurrencyValueRangeType;
};
// TODO: remove sourceOfWealth in the future when BE KYC/financialInfo response is udpated
export interface VerifyContext {
  accountState: AccountStateContext;
  legalName?: LegalName;
  birthdate?: string;
  address?: Partial<UserAddress>;
  documentUploads?: DocumentUpload[];
  hasTaxIdNumber?: boolean;
  hasFinancialInfo?: boolean;
  hasConsented?: boolean;
  phoneNumber?: PhoneNumber;
  sourceOfWealth?: string;
  financialInfo?: Partial<VerifyContextFinancialInfo>;
  exchangeUsage?: Partial<VerifyExchangeUsage>;
  requireLivenessCheck?: boolean;
}

// BE country config endpoint contract
type CountryDocumentConfigDocumentSet = {
  firstDocuments: Array<OnfidoDocumentKeyType>;
  secondDocuments: Array<OnfidoDocumentKeyType>;
};
export type CountryDocumentResponseType = {
  countryCode: string;
  documentSet: CountryDocumentConfigDocumentSet;
};

// Used by state machine
export type CountryConfigState = {
  useMultiDocFlow: boolean;
  useSingleDocFlow: boolean; // if false will move to fallback
  firstDocuments: Partial<Record<DocumentTypes, boolean>>;
  secondDocuments: Partial<Record<DocumentTypes, boolean>>;
};

export interface CreditCardParams {
  inCreditCard?: Boolean;
  inCardDetails?: Boolean;
}
