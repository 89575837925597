import {
  CurrencyShortName,
  CurrencyShortNameCrypto,
  CurrencyShortNameFiat,
} from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { AnnualInterestYieldTier } from "@gemini-ui/constants/earn";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { AccountType, ChartInterval, DashboardMarketProps, Point } from "@gemini-ui/pages/RetailTrade/constants";
import { RetailTradePaymentMethodType } from "@gemini-ui/transformers/PaymentMethods";

export enum Action {
  BUY = "Buy",
  SELL = "Sell",
  CONVERT = "Convert",
}

export enum Step {
  PLACE_ORDER = "Place Order",
  SELECT_ORDER_TYPE = "Select Order Type",
  REVIEW_ORDER = "Review Order",
  REVIEW_LIMIT_ORDER = "Review Limit Order",
  SUCCESS = "Success",
  LIMIT_ORDER_SUCCESS = "Limit Order Success",
}

export const INSUFFICIENT_FUNDS_ERROR = "Please enter an amount less than or equal to your current available balance.";
export const GENERIC_3DS_ERROR = "There was a problem communicating with the server.";
export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

export interface CurrencyDetails {
  currency: CurrencyShortName;
  value: string;
  changePercent: string | null;
  changeAmount: string | null;
}

export enum BuyingFrequency {
  Once = "Once",
  Daily = "Daily",
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Monthly = "Monthly",
}

export const DAYS_NUM_MAP = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export enum DAYS_ENUM {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

// TODO: This needs some work - should now be a raw object
export const TWICE_MONTHLY_NUM_MAP = {
  FirstAndFifteenth: [1, 15],
  SecondAndSixteenth: [2, 16],
  ThirdAndSeventeenth: [3, 17],
  FourthAndEighteenth: [4, 18],
  FifthAndNineteenth: [5, 19],
  SixthAndTwentieth: [6, 20],
  SeventhAndTwentyFirst: [7, 21],
  EighthAndTwentySecond: [8, 22],
  NinthAndTwentyThird: [9, 23],
  TenthAndTwentyFourth: [10, 24],
  EleventhAndTwentyFifth: [11, 25],
  TwelfthAndTwentySixth: [12, 26],
  ThirteenthAndTwentySeventh: [13, 27],
  FourteenthAndTwentyEighth: [14, 28],
};

// TODO: This needs some work - should now be a raw object
export const MONTHLY_NUM_MAP = {
  First: [1],
  Second: [2],
  Third: [3],
  Fourth: [4],
  Fifth: [5],
  Sixth: [6],
  Seventh: [7],
  Eighth: [8],
  Ninth: [9],
  Tenth: [10],
  Eleventh: [11],
  Twelfth: [12],
  Thirteenth: [13],
  Fourteenth: [14],
  Fifteenth: [15],
  Sixteenth: [16],
  Seventeenth: [17],
  Eighteenth: [18],
  Nineteenth: [19],
  Twentieth: [20],
  TwentyFirst: [21],
  TwentySecond: [22],
  TwentyThird: [23],
  TwentyFourth: [24],
  TwentyFifth: [25],
  TwentySixth: [26],
  TwentySeventh: [27],
  TwentyEighth: [28],
};

export type RecurringFrequency = {
  weekly: DAYS_ENUM;
  twiceMonthly: number[];
  monthly: number[];
};

export type TradePaymentMethodType = RetailTradePaymentMethodType | AccountType.BALANCE;

export interface CurrencyOrderQuote {
  annualInterestYieldTiers?: AnnualInterestYieldTier[];
  annualPercentYield?: number;
  approxUsd: MoneyProps;
  approxNotional?: MoneyProps;
  approxQuoteNotional?: MoneyProps;
  fee: MoneyProps;
  approxFeeNotional?: MoneyProps;
  totalSpend: MoneyProps;
  proceeds: MoneyProps;
  approxSpendNotional?: MoneyProps;
  depositFee: MoneyProps;
  quantity: MoneyProps;
  transactionCostNotional?: MoneyProps;
  transactionCost: MoneyProps;
  formData: {
    isDirectlyFundingDerivativeAccount?: boolean;
    earnProviderId?: string;
    fee: string;
    depositFee?: string;
    from: CurrencyShortNameCrypto | CurrencyShortNameFiat;
    internalReference?: string;
    paymentMethodType?: string;
    paymentMethodUuid?: string;
    priceCurrency: CurrencyShortNameCrypto | CurrencyShortNameFiat;
    promoMultiplier: number;
    price: string;
    quantity: string;
    quoteId: number;
    to: CurrencyShortNameCrypto | CurrencyShortNameFiat;
  };
}

export interface OrderQuoteError {
  error: string;
  data: string;
}

export interface OrderQuote {
  canPlaceOrder: boolean;
  lockout: string | null;
  quote: CurrencyOrderQuote;
  quoteError?: OrderQuoteError;
}

type OrderType = "Instant" | "Recurring";
export interface TrackTrade {
  orderType: OrderType;
  schedule: BuyingFrequency;
  tradingPair: string;
  side: "buy" | "sell" | "convert";
  quantity?: string;
  total?: string | number;
  price?: string;
  fee?: string;
  dayOfWeek?: string;
  dates?: number[];
  triggerHour?: string;
  quickBuy?: boolean;
  usedSuggestedAmounts?: boolean;
  paymentMethod: AccountType;
  defaultFiat?: CurrencyShortNameFiat;
  assetName?: string;
  assetEarnApy?: string | number;
  currency?: string;
  value?: string | number;
  pageName?: PageName;
}

export interface OrderResult {
  fee: string;
  orderId: number;
  price: string;
  quantity: string;
  availableToWithdraw?: MoneyProps; // `availableToWithdraw` should be returned in possibility of a hold, like with GUSD.
}

export const TIME_FRAMES = {
  "1h": {
    apiTimeInterval: "1m",
    intervalSkip: 1,
  },
  "1d": {
    apiTimeInterval: "1m",
    intervalSkip: 6,
  },
  "1w": {
    apiTimeInterval: "5m",
    intervalSkip: 6,
  },
  "1m": {
    apiTimeInterval: "30m",
    intervalSkip: 4,
  },
  "1y": {
    apiTimeInterval: "1day",
    intervalSkip: 1,
  },
};

export type ConvertEligible =
  | {
      [key in CurrencyShortNameCrypto]: CurrencyShortNameCrypto[];
    }
  | {};

export interface AssetDetailState {
  chartData: Point[];
  interval: ChartInterval;
  limitOrderUpdates: number;
  selectedPrice: string | null;
}

export interface AssetDetailPageProps {
  convertEligible: ConvertEligible;
  marketProps: DashboardMarketProps;
}

interface CoinMarketRepos {
  github: string[];
}

interface CoinMarketDataLinks {
  homepage: string[];
  subreddit_url: string;
  whitepaper: string;
  repos_url: CoinMarketRepos;
  twitter_screen_name?: string;
}

interface CoinGeckoDataValue {
  [key: string]: number;
}

interface CoinGeckoDateValueString {
  [key: string]: string;
}
interface CoinGeckoMarketData {
  market_cap: CoinGeckoDataValue;
  market_cap_fdv_ratio: number;
  total_volume: CoinGeckoDataValue;
  circulating_supply: number;
  total_supply: number;
  ath: CoinGeckoDataValue;
  ath_change_percentage: CoinGeckoDataValue;
  ath_date: CoinGeckoDateValueString;
  fully_diluted_valuation: CoinGeckoDataValue;
  max_supply?: number;
  total_value_locked?: CoinGeckoDataValue;
}

export interface CoinMarketData {
  links: CoinMarketDataLinks;
  market_data: CoinGeckoMarketData;
}
