import styled from "@emotion/styled";
import { Colors } from "@gemini-ui/design-system";

export const Uploader = styled.div`
  min-height: 600px;
`;

export const OnfidoLoadingPlaceholder = styled.div`
  height: 600px;
  width: 423px;
  border-radius: 20px;
  background: ${Colors.gray[50]};
`;
