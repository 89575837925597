import { PhoneNumber } from "@gemini-ui/constants/phoneNumber";
import { Colors } from "@gemini-ui/design-system";

export type DocumentTypes =
  | "passport"
  | "driving_licence"
  | "national_identity_card"
  | "residence_permit"
  | "generic_document";

export type RequestedVariant = "video" | "motion" | "standard";

type SdkErrorTypes =
  | "invalid_token"
  | "expired_token"
  | "expired_trial"
  | "geoblocked_request"
  | "permissions_unavailable"
  | "exception"
  | "unsupported"
  | "unsupported_feature"
  | "invalid_sdk_parameter"
  | "restart"
  | "desktop"
  | "unsupported_sdk_version"
  | "no_camera"
  | "user_consent_denied"
  | "uncaught_fetch_error"
  | "api_error"
  | "cross_device_verification_invalid"
  | "cross_device_verification_abort"
  | "workflow_abandoned"
  | "workflow_error";

export type SdkError = {
  type: SdkErrorTypes;
  message: string;
};

export interface DocumentUploaderProps {
  onfidoSDKToken: string;
  onComplete: (any) => void;
  onError?: (error: SdkError) => void;
  handleDialogOpen?: () => void;
  handleDialogClose?: () => void;
  isDialogOpen?: boolean;
  isLoading: boolean;
  welcome?: {
    title?: string;
    descriptions?: string[];
    nextButton?: string;
  };
  documentTypes: Partial<Record<DocumentTypes, boolean>>;
  poa?: {
    country?: "GBR" | "other"; // GBR type automatically enables the document types marked below.
    documentTypes?: {
      bank_building_society_statement?: boolean;
      utility_bill?: boolean;
      council_tax?: boolean; // GBR only
      benefit_letters?: boolean; // GBR only
      government_letter?: boolean; // non-GBR only
    };
  };
  confirmation?: {
    message?: string;
    submessage?: string;
  };
  phoneNumber?: PhoneNumber;
  requestedLivenessVariant?: RequestedVariant;
  forceCrossDevice?: boolean;
}

export enum OnfidoTheme {
  Light = "light",
  Dark = "dark",
}

export const ONFIDO_SDK_STATE = {
  initializing: "initializing",
  ready: "ready",
  failed: "failed",
};

export const ONFIDO_CUSTOM_UI = {
  fontFamilyBody:
    "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  fontSizeBody: "14px",
  colorContentBody: Colors.black,
  // title
  colorContentTitle: Colors.black,
  fontFamilyTitle: "inherit",
  fontSizeTitle: "32px",
  // subtitle
  colorContentSubtitle: Colors.black,
  fontSizeSubtitle: "14px",
  fontFamilySubtitle: "inherit",
  // primary buttons
  colorBackgroundButtonPrimary: Colors.black,
  colorBorderButtonPrimary: Colors.black,
  colorBorderButtonPrimaryHover: Colors.black,
  colorBackgroundButtonPrimaryHover: Colors.gray[900],
  colorBackgroundButtonPrimaryActive: Colors.gray[800],
  // secondaryButton
  colorContentButtonSecondaryText: Colors.black,
  colorBackgroundButtonSecondary: Colors.white,
  colorBorderButtonSecondary: Colors.white,
  colorBackgroundButtonSecondaryHover: Colors.gray[50],
  colorBackgroundButtonSecondaryActive: Colors.gray[100],
  // tertiaryButton
  colorContentButtonTertiaryText: Colors.black,
  // links
  colorContentLinkTextHover: Colors.gray[800],
  colorBackgroundLinkHover: Colors.white,
  colorBorderLinkUnderline: Colors.black,
  colorBackgroundLinkActive: Colors.white,
  // doc type buttons
  colorBorderDocTypeButtonHover: Colors.gray[50],
  colorContentDocTypeButton: Colors.black,
  colorBorderDocTypeButton: Colors.gray[100],
  // doc type icons
  colorBackgroundIcon: Colors.gray[50],
  // pills
  colorContentInfoPill: Colors.white,
  colorBackgroundInfoPill: Colors.black,
};

export const DEFAULT_LOCALE = "en";
export const CONTAINER_ID = "onfido-mount";
export const ONFIDO_EVENT_LISTENER = "userAnalyticsEvent";
export const CONTENTFUL_BRAND_ASSET =
  "//images.ctfassets.net/jg6lo9a2ukvr/3fTLFAWIE5uj7pAIkAST64/5d3b70ef8af71e3d0b31c6177071342b/Stacked_logo_for_OnFido-Light_mode.png";
